@import '../../styles/abstract.scss';

.default {
	min-height: 10px; // fix, to show footer if inView (when user scroll to footer) after SSR

	@media print {
		display: none;
	}

	@include media-below(sm) {
		padding-bottom: 50px;
	}

	[class*="col-"] {
		@include flexbox();
		@include justify-content(space-between);
		align-items: center;

		@include media-below(md) {
			@include flexbox();
			@include flex-direction(column);
		}

		div {
			@include flexbox();

			@include media-below(md) {
				@include flex-direction(column);
			}

			+ div {
				@include media-below(md) {
					margin-top: 30px;
				}
			}
		}

		[class*="language"], [class*="social"] {
			@include media-below(md) {
				@include flex-direction(row);
				justify-content: center;
				margin-top: 5px;
			}
		}
	}
}

.top {
	border-top: 1px solid $border-color-light;
	border-bottom: 1px solid $border-color-light;
	padding: 60px 0;

	nav {
		a, button {
			font-weight: $font-medium;
			font-size: 14px;

			@include media-below(sm) {
				display: block;
			}
		}
	}

	.social {
		a {
			&:not(:last-child) {
				margin-right: 15px;
			}
		}
	}
}

.bottom {
	padding: 30px 0;
	color: $text-gray-light;
	font-size: 12px;

	@include media-below(md) {
		text-align: center;
	}

	a {
		color: inherit;
	}

	.logo {
		@include background-image($logo-positive, 197, 32);
		display: block;
	}

	nav {
		@include media-below(md) {
			@include flex-direction(row);
			margin-bottom: 15px;
		}

		a {
			margin-right: 0!important;
			text-decoration: none;
			font-size: inherit;

			&:not(:last-child) {
				&:after {
					content: '·';
					position: relative;
					margin-left: 10px;
					margin-right: 10px;
				}
			}

			&:hover {
				color: $text-gray;
			}
		}
	}

	p {
		@include media-below(sm) {
			margin: 20px 0 10px;
		}
	}

	.reset {
		button {
			margin-left: 10px;
			display: none;
		}

		&:hover {
			button {
				display: inline-block;
			}
		}
	}
}

// iOS fix for tooblar bottom (new iOS has "ui" element sticked to bottom and overlay buttons)
[class*="ios"] {
	.toolbar {
		> div {
			padding-bottom: 20px;
		}
	}
}

.toolbar {
	position: fixed;
	bottom: -2px;
	width: 100%;
	background-color: $white;
	z-index: 9;
	text-align: center;

	@media print {
		display: none!important;
	}

	> div {
		padding: 10px 20px 8px 20px;

		position: relative;
		z-index: 1;
		width: 100%;
		@include flexbox();
		box-shadow: 0 0 10px rgba(0,0,0,0.15);
		@include justify-content(center);
	}

	a, button {
		@include flexbox();
		@include align-items(center);
		@include flex-direction(column);
		font-size: 12px;
		color: #8898AABD;
		font-family: $font-family-roboto;
		width: 100%;
		line-height: 1.5;
		z-index: 1;
		text-decoration: none;

		i {
			display: block;
			margin-bottom: 1px;
		}

		&.active {
			color: $gray-dark;

			i {
				&[class*="home"] { @include icon-image-only('home-primary.svg'); }
				&[class*="profile"] { @include icon-image-only('profile-primary.svg'); }
				&[class*="magnifier"]  { @include icon-image-only('magnifier-primary.svg'); }
				&[class*="heart-solid"]  { @include icon-image-only('heart-solid-primary.svg'); }
			}
		}

	}

	nav {
		@include mobile-navigation-fixed();
		padding: 20px 0;
		display: flex;
		justify-content: flex-start;

		a, button {
			@include flex-direction(row);
			font-size: 15px;
			font-family: $font-family-default;
			font-weight: 600;
			color: $gray-dark;
			margin: 0;
			padding: 12px 20px;

			i {
				margin-right: 20px;
			}
		}

		[class*="navigation_separator"] {
			border-bottom: 1px solid $section-separator-color;
			width: 100%;
			margin: 12px 0;
		}

		h2 {
			font-size: 24px;
			font-weight: 700;
			text-align: left;
			width: 100%;
			padding: 0 20px;
			margin-bottom: 25px;
		}
	}
}
